<template>
  <modal-template
    v-if="activeModal === SIGN_IN || activeModal === SIGN_IN_PURCHASE"
    size="intermedium"
    :is-static="isStatic"
    @close="closeModal"
  >
    <wegow-loader v-if="loading" class="load-more-loader" :middle="true" />
    <div v-else id="containerSignin">
      <div class="pt-4 md:pt-12 md:mx-16">
        <h3 class="mb-8 topaz-light">{{ $t("wegow.login.loginTitle") }}</h3>
        <div>
          <form @submit.prevent="signinEmail">
            <div>
              <wegow-input
                id="email-signin"
                v-model:value="model.email"
                :placeholder="$t('wegow.login.email')"
                type="email"
                name="email"
                required
                :validator="validators.email"
                @valid="valid.email = $event"
              />
            </div>
            <div class="mt-3">
              <wegow-input
                id="password-signin"
                v-model:value="model.password"
                :placeholder="$t('wegow.login.password')"
                type="password"
                name="password"
                required
                :validator="validators.password"
                @valid-change="valid.password = $event"
              />
              <div class="text-end forgot-password mt-3 cursor-pointer">
                <a @click="openModal(FORGET_PASSWORD)">{{
                  $t("wegow.login.forgetPassword")
                }}</a>
              </div>
            </div>
            <span v-if="loginError" class="text-orange-errors"
              >El correo o la contraseña son incorrectos</span
            >
            <div class="text-center mt-6">
              <wegow-button
                background="opacity"
                color="topaz"
                type="submit"
                :disabled="isDisabled"
                :text="$t('wegow.login.enter')"
              />
            </div>
          </form>
        </div>
        <!-- <div class="or-title my-5">
          <span class="or-line">{{ $t("wegow.login.or") }}</span>
        </div>
        <div class="flex text-center">
          <google-signin-button
            :params="fbSignInParams"
            class="google-btn sign"
            @success="onSignInSuccess"
          >
            <i class="google-icon" />
            {{ $t("wegow.login.loginWithGoogle") }}
          </google-signin-button>
        </div> -->

        <div class="text-center">
          <p
            class="text-mignup underline cursor-pointer mt-6"
            @click="
              activeModal === SIGN_IN
                ? openModal(SIGN_UP)
                : openModal(SIGN_UP_PURCHASE)
            "
          >
            {{ $t("wegow.login.createAccount") }}
          </p>
        </div>
      </div>
      <div
        v-if="activeModal === SIGN_IN"
        class="promoter-signup mt-5 flex justify-center items-center cursor-pointer"
        @click="openModal(SIGN_UP_PROMOTER)"
      >
        <p class="text-mignup-promoter">
          {{ $t("wegow.login.createPromoterAccount") }}
        </p>
      </div>
    </div>
  </modal-template>
</template>

<script setup>
import { useUser } from "@/composables/useUser";
import { usePurchase } from "@/composables/usePurchase";

import WegowButton from "@/components/general/forms/WegowButton";
import WegowInput from "@/components/general/forms/WegowInput";
import ModalTemplate from "@/components/general/modals/ModalTemplate.vue";
import WegowLoader from "@/components/general/ui/WegowLoader";

import { useModalHandler } from "@/plugins/useModalHandler";
import { computed, reactive, ref } from "vue";
import { useRouter, useRoute } from "vue-router";

const {
  activeModal,
  SIGN_IN,
  SIGN_UP,
  SIGN_UP_PROMOTER,
  SIGN_IN_PURCHASE,
  SIGN_UP_PURCHASE,
  FORGET_PASSWORD,
  openModal,
  closeModal
} = useModalHandler();

const { userDetail, signIn } = useUser();
const { createPurchase } = usePurchase();
const router = useRouter();
const route = useRoute();

const props = defineProps({
  isStatic: {
    type: Boolean,
    default: false
  },
  // TODO: qué son origin y newPromoter
  origin: {
    type: String,
    default: ""
  },
  newPromoter: {
    type: Boolean,
    default: false
  }
});

const loginError = ref(false);
const loading = ref(false);

const modelTemplate = { email: "", password: "" };
const model = reactive({ ...modelTemplate });
const valid = reactive({ email: false, password: false });

const validators = {
  email: { validate: email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email || "") },
  password: { validate: password => /^.{8,}$/.test(password) }
};

const isDisabled = computed(() => Object.values(valid).includes(false));

const resetModel = () => Object.assign(model, modelTemplate);

const signinEmail = async () => {
  try {
    loading.value = true;
    await signIn(model);
    if (activeModal.value === SIGN_IN_PURCHASE) {
      await createPurchase(true);
    } else if (!route.params.region) {
      router.push({
        name: "MainRegionPage",
        params: { region: userDetail.value.region || "es" }
      });
    }
    closeModal();
  } catch (error) {
    loginError.value = error;
  }
  loading.value = false;
};

const onSignInSuccess = async response => {
  await signIn(response);
  // TODO: revisar el inicio de sesion con google
  resetModel();
  closeModal();
};
</script>
<style lang="scss" scoped>
#containerSignin {
  h3 {
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
  }
  .forgot-password {
    color: $topaz;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
  }
  .or-title {
    position: relative;
    display: block;
    width: 100%;
    margin: 10px 0;
    text-align: center;
    font-size: 16px;
    z-index: 1;
    overflow: hidden;
    &:before,
    &:after {
      content: "\a0";
      position: absolute;
      top: 51%;
      width: 25%;
      height: 1px;
      background-color: $white;
      overflow: hidden;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
}
.promoter-signup {
  background-image: url("@/assets/images/gradient-banner-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-left: -25px;
  margin-right: -25px;
  margin-bottom: -25px;
  height: 58px;
  p {
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
  }
}
/* .otherLoginMethods {
  display: flex;
  flex-direction: column;
}
.google-btn {
  margin-top: 6px;
  cursor: pointer; */
/* This is where you control how the button looks. Be creative! */
/* display: inline-block;
  padding: 4px 8px;
  border-radius: 3px;
  background-color: #3c82f7;
  color: #fff;
  box-shadow: 0 3px 0 #0f69ff;
} */
</style>
