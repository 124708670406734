import httpClient from "@/api/axios.config";

export const apiService = {
  get: (resource, params) => httpClient.get(resource, params),
  post: (resource, params, headers = {}) =>
    httpClient.post(resource, params, headers),
  patch: (resource, data, params) => httpClient.patch(resource, data, params),
  put: (resource, params) => httpClient.put(resource, params),
  delete: (resource, params) => httpClient.delete(resource, params)
};

export default apiService;
