<template>
  <div>
    <div
      :id="values.id + numberId"
      :class="card ? 'is-card' : ''"
      @click="sendGA(type)"
    />
  </div>
</template>

<script>
export default {
  props: {
    adstype: {
      type: String,
      default: ""
    },
    position: {
      type: Number,
      default: undefined
    },
    values: {
      type: Object,
      default: () => {
        return {};
      }
    },
    card: {
      type: Boolean,
      default: false
    }
  },
  emits: ["emptyAd"],
  data: () => ({
    slot: "",
    sendAnalitics: false,
    numberId: parseInt(Math.random() * 1000)
  }),
  mounted() {
    setTimeout(() => {
      // Evento que escucha cuando ha acabado de renderizar el anuncio en el Iframe,
      // para chequear si ese anuncio esta vacío
      // if (!window.googletag.pubads) {
      //   this.$emit("emptySliderAds", { ad: this.values });
      // }
      // Creación del slot de anuncio, aquí le indicamos el id del div donde tiene que incluirlo
      var self = this;
      (() => {
        function createSlot(type, values) {
          window.googletag.cmd = window.googletag.cmd || [];
          window.googletag.cmd.push(function() {
            self.slot = window.googletag.defineSlot(
              `/5555,21692105105/WEGOW.ES/${type}`,
              ["fluid"],
              values.id + self.numberId
            );
            window.googletag
              .pubads()
              .addEventListener("slotRenderEnded", event => {
                if (self.slot === event.slot) {
                  if (event.isEmpty) {
                    if (!event.slot.dynamic) {
                      console.log("empty id: ", event.slot?.id);
                      console.log("empty: ", event.slot);
                      self.$emit("emptyAd", { ad: self.values });
                    } else {
                      console.log("empty dynamic id: ", event.slot?.id);
                      console.log("empty dynamic: ", event.slot);
                      var dynamic = document.getElementById(
                        "wegow-ads-dynamic"
                      );
                      if (dynamic.parentNode)
                        dynamic.parentNode.removeChild(dynamic);
                      self.$root.$emit("emptyDynamicAd");
                    }
                  } else {
                    if (!self.sendAnalitics) {
                      if (
                        self.$gtag &&
                        typeof self.$gtag.event === "function"
                      ) {
                        // self.$gtag.event(type, {
                        //   'event_category': 'ads'
                        // });
                        self.sendAnalitics = true;
                      }
                    }
                  }
                }
              });
            if (self.slot && typeof self.slot.addService === "function") {
              self.slot.addService(window.googletag.pubads());
              self.slot.position = values.wegow_position;
              if (values) {
                self.slot.id = values.id + self.numberId;
                for (const key in values) {
                  self.slot.setTargeting(key, values[key]);
                  self.slot[key] = values[key];
                }
              }
              if (self.adstype === "wegow-web-dynamic-ad") {
                self.slot.dynamic = true;
              }
              window.googletag.display(values.id + self.numberId);
              window.googletag.pubads().refresh([self.slot]);
            }
          });
        }
        // if (typeof window.canRunAds !== "undefined" && window.canRunAds) {
        createSlot(this.adstype, this.values);
        // } else {
        //   self.$emit("emptyAd", { ad: this.values });
        // }
        setTimeout(() => {
          const divNuevoAdd = document.getElementById(
            this.values.id + this.numberId
          );
          if (divNuevoAdd) {
            const iframe = divNuevoAdd.querySelector("iframe");
            if (!iframe) {
              this.$emit("emptyAd", this.values.id);
            }
          }
        }, 2000);
      })();
    }, 1000);
  },
  methods: {
    sendGA(eventName) {
      if (this.$gtag && typeof this.$gtag.event === "function") {
        // this.$gtag.event(eventName + '_click', {
        //   'event_category': 'ads'
        // });
      }
    }
  }
};
</script>
<style scoped lang="scss">
div {
  width: 100% !important;
  height: 100% !important;
}
</style>

<style lang="scss">
.is-card {
  width: 100% !important;
  height: 100% !important;
  div,
  iframe {
    width: 100% !important;
    height: 100% !important;
  }
}
</style>

<!-- <script setup>
// import { onMounted, ref } from "vue";

// const props = defineProps({
//   adstype: {
//     type: String,
//     default: ""
//   },
//   position: {
//     type: Number,
//     default: 1
//   },
//   values: {
//     type: Object,
//     default: () => {
//       return {};
//     }
//   }
// });

// const emit = defineEmits(["emptySliderAds", "emptyDynamicAd"]);

// const slot = ref("");
// const sendAnalitics = ref(false);

// onMounted(() => {
//   setTimeout(() => {
//     // Evento que escucha cuando ha acabado de renderizar el anuncio en el Iframe,
//     // para chequear si ese anuncio esta vacío
//     if (!window.googletag.pubads) {
//       emit("emptySliderAds", { ad: props.values });
//     }
//     // Creación del slot de anuncio, aquí le indicamos el id del div donde tiene que incluirlo
//     var self = this;
//     (() => {
//       function createSlot(type, values) {
//         window.googletag.cmd = window.googletag.cmd || [];
//         window.googletag.cmd.push(function() {
//           self.slot = window.googletag.defineSlot(
//             `/5555,21692105105/WEGOW.ES/${type}`,
//             ["fluid"],
//             values.id
//           );
//           window.googletag
//             .pubads()
//             .addEventListener("slotRenderEnded", event => {
//               if (self.slot === event.slot) {
//                 if (event.isEmpty) {
//                   if (!event.slot.dynamic) {
//                     console.log("empty id: ", event.slot.id);
//                     console.log("empty: ", event.slot);
//                     self.emit("emptySliderAds", { ad: self.values });
//                   } else {
//                     console.log("empty dynamic id: ", event.slot.id);
//                     console.log("empty dynamic: ", event.slot);
//                     var dynamic = document.getElementById("wegow-ads-dynamic");
//                     if (dynamic.parentNode)
//                       dynamic.parentNode.removeChild(dynamic);
//                     self.$root.emit("emptyDynamicAd");
//                   }
//                 } else {
//                   if (!self.sendAnalitics) {
//                     if (self.$gtag && typeof self.$gtag.event === "function") {
//                       // self.$gtag.event(type, {
//                       //   'event_category': 'ads'
//                       // });
//                       self.sendAnalitics = true;
//                     }
//                   }
//                 }
//               }
//             });
//           if (self.slot && typeof self.slot.addService === "function") {
//             self.slot.addService(window.googletag.pubads());
//             self.slot.position = values.wegow_position;
//             if (values) {
//               self.slot.id = values.id;
//               for (const key in values) {
//                 self.slot.setTargeting(key, values[key]);
//                 self.slot[key] = values[key];
//               }
//             }
//             if (self.adstype === "wegow-web-dynamic-ad") {
//               self.slot.dynamic = true;
//             }
//             window.googletag.display(values.id);
//             window.googletag.pubads().refresh([self.slot]);
//           }
//         });
//       }
//       // if (typeof window.canRunAds !== "undefined" && window.canRunAds) {
//       createSlot(props.adstype, props.values);
//       // } else {
//       //   self.emit("emptySliderAds", { ad: props.values });
//       // }
//     })();
//   }, 1500);
// });

// const sendGA = eventName => {
//   if (this.$gtag && typeof this.$gtag.event === "function") {
//     // this.$gtag.event(eventName + '_click', {
//     //   'event_category': 'ads'
//     // });
//   }
// };
</script> -->
