import { defineStore } from "pinia";
import PurchaseApi from "@/api/PurchaseApi";

export const usePurchaseStore = defineStore("purchase", {
  state: () => {
    return {
      actualPurchase: {},
      purchaseData: {},
      bannerAd: {},
      nominativeData: {},
      validator: [],
      newsletter: false,
      termsAccepted: false,
      purchaseExtraData: {},
      accessCodeValid: true,
      accessCode: "",
      maxNumberTicketByCode: -1,
      seatsioData: {},
      referenceParams: {},
      isWhiteLabel: ""
    };
  },

  actions: {
    async createPurchase(params) {
      this.purchaseData = await PurchaseApi.createPurchase(
        params,
        this.referenceParams
      );
    },
    async createSeatsioPurchase(params) {
      this.purchaseData = await PurchaseApi.createSeatsioPurchase(
        params,
        this.referenceParams
      );
    },
    async createTransfer(params) {
      this.purchaseData = await PurchaseApi.createTransfer(
        params,
        this.referenceParams
      );
    },
    async getThankAd(params) {
      this.bannerAd = await PurchaseApi.getThankAd(params);
    },
    async getPurchase(params) {
      this.purchaseData = await PurchaseApi.getPurchase(params);
    },
    async checkIfEmailIsUnique(params) {
      try {
        await PurchaseApi.checkIfEmailIsUnique(params);
        return true;
      } catch (error) {
        return false;
      }
    },
    async validateAccessCode(params) {
      try {
        let accessCodeData = await PurchaseApi.validateAccessCode(params);
        if (accessCodeData.code) {
          this.accessCode = accessCodeData.code;
          this.maxNumberTicketByCode = accessCodeData.max_num_tickets || -1;
          this.accessCodeValid = true;
          return null;
        } else {
          this.accessCodeValid = false;
          return "Código de acceso no válido";
        }
      } catch (e) {
        this.accessCodeValid = false;
        return e.data.error ? e.data.error.trim() : e.data.trim();
      }
    },
    setReference(reference) {
      this.referenceParams = reference;
    },
    async editWeswapTicket(params) {
      return await PurchaseApi.editWeswapTicket(params);
    },
    setSeatsioData(data) {
      this.seatsioData = data;
    }
  }
});
