<template>
  <div
    :style="getPersonalizedCheckbox"
    class="w-radio"
    :class="[disabled ? 'cursor-not-allowed' : 'cursor-pointer']"
    @click="!disabled && !radio.checked && check(value)"
  >
    <input
      :id="radioId"
      ref="radio"
      :name="name"
      :value="value"
      :checked="groupValue === value"
      :disabled="disabled"
      type="radio"
      class="mr-1 w-4 h-4 md:h-auto md:w-auto"
    />

    <div class=" select-none flex flex-row gap-2 text-base w-auto text-left">
      <span v-if="label">{{ label }}</span>
      <slot v-else name="label" />
    </div>
  </div>
</template>
<script setup>
import { computed } from "@vue/reactivity";
import { ref, toRefs } from "vue";
import { useIframeStyle } from "@/composables/useIframeStyle";

const { getPersonalizedCheckbox } = useIframeStyle();

const emit = defineEmits(["update:group-value"]);

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  label: {
    type: String,
    default: undefined
  },
  name: {
    type: String,
    required: true
  },
  value: {
    type: String,
    default: undefined
  },
  disabled: {
    type: Boolean,
    default: false
  },
  groupValue: {
    type: String,
    default: undefined
  }
});

const { id, label, name, value, disabled, groupValue } = toRefs(props);
const radio = ref(null);
const radioId = computed(
  () => `${id.value}_${Math.floor(Math.random() * Math.pow(10, 10))}`
);

const check = value => emit("update:group-value", value);
</script>

<style lang="scss" scoped>
.w-radio {
  @apply flex flex-row gap-2;

  input[type="radio"] {
    display: none;
  }

  input[type="radio"] + div::before {
    content: "";
    margin: auto;
    width: 1.1rem;
    height: 1.1rem;
    border-radius: 50%;
    border-style: solid;
    border-width: 0.13rem;
    border-color: $grey;
  }

  input[type="radio"]:checked + div::before {
    background: radial-gradient(
      $topazclaro 0%,
      $topazclaro 30%,
      transparent 40%,
      transparent
    );
    border-color: $topazclaro;
  }

  input[type="radio"]:disabled + div::before {
    border-color: $grismedio;
  }

  input[type="radio"]:checked:disabled + div::before {
    background: radial-gradient(
      $grismedio 0%,
      $grismedio 30%,
      transparent 40%,
      transparent
    );
    border-color: $grismedio;
  }
}
</style>
