import moment from "moment-timezone";
import { DEFAULT_LANGUAGES } from "@/constants.js";

export function formatDate(date, locale, isStreaming = false) {
  if (!isStreaming) {
    date = moment(date)
      .locale(DEFAULT_LANGUAGES[locale] || "es")
      .utc();
  } else {
    date = moment(date).locale(DEFAULT_LANGUAGES[locale] || "es");
  }
  return date.format("D MMM YYYY");
}
export function formatDayWeekDate(date, locale, isStreaming = false) {
  if (!isStreaming) {
    date = moment(date)
      .locale(DEFAULT_LANGUAGES[locale] || "es")
      .utc();
  } else {
    date = moment(date).locale(DEFAULT_LANGUAGES[locale] || "es");
  }
  const dayOfWeek = date.format("dddd");
  const day = date.format("D");
  const month = date.format("MMMM");
  const year = date.format("YYYY");
  const hour = date.format("HH:mm");
  const day_format = [
    "st",
    "nd",
    "rd",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "st",
    "nd",
    "rd",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "st"
  ];
  return {
    dayweek: dayOfWeek,
    day: day,
    month: month,
    sufix: day_format[day - 1],
    year: year,
    hour
  };
}
export function formatHourSimple(date, locale) {
  return new Date(date).toLocaleTimeString(locale, {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false
  });
}
export function yearPeriodFormatter() {
  let actualDate = new Date();
  if (actualDate.getMonth() + 1 >= 5 && actualDate.getMonth() + 1 < 12) {
    return `${new Date().getFullYear()}-${new Date().getFullYear() + 1}`;
  } else {
    return `${new Date().getFullYear()}`;
  }
}
export function formatHour(date, locale, isStreaming = false) {
  if (!isStreaming) {
    date = moment(date)
      .locale(DEFAULT_LANGUAGES[locale] || "es")
      .utc();
  } else {
    date = moment(date).locale(DEFAULT_LANGUAGES[locale] || "es");
  }
  return date.format("HH:mm");
}
export function formatLongDate(date, locale, isStreaming = false) {
  if (!isStreaming) {
    date = moment(date)
      .locale(DEFAULT_LANGUAGES[locale] || "es")
      .utc();
  } else {
    date = moment(date).locale(DEFAULT_LANGUAGES[locale] || "es");
  }
  const day = date.format("D");
  const month = date.format("MMMM");
  const year = date.format("YYYY");
  const day_format = [
    "st",
    "nd",
    "rd",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "st",
    "nd",
    "rd",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "th",
    "st"
  ];
  return { day: day, month: month, sufix: day_format[day - 1], year: year };
}
export function formatNumbersDate(date, locale, isStreaming = false) {
  if (!isStreaming) {
    date = moment(date)
      .locale(DEFAULT_LANGUAGES[locale] || "es")
      .utc();
  } else {
    date = moment(date).locale(DEFAULT_LANGUAGES[locale] || "es");
  }
  return date.format("YYYYMMDDTHHmmss");
}
