import apiService from "@/api/ApiService";
import UserApi from "@/api/UserApi";
import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  state: () => {
    return {
      loadingInit: true,
      loadingTickets: true,
      gettingPerfectCompanion: false,
      initialConfig: {},
      userTokens: {},
      userDetail: {},
      friends: [],
      user: {},
      users: {},
      list: {
        users: [],
        chatMembers: []
      },
      listUsers: {
        users: [],
        chatMembers: []
      },
      genres: [],
      userStatus: {},
      artistFollowers: [],
      eventFollowers: [],
      venueFollowers: [],
      campaignFollowers: [],
      companyFollowers: {},
      usersLikedMoment: {},
      cards: [],
      location: {},
      started: false,
      perfectCompanion: {},
      myTickets: [],
      weswapEvents: {},
      invoices: [],
      purchasesCoverage: [],
      buyer: {},
      serverError: false,
      maintenancePage: false
    };
  },
  actions: {
    async refresh_token(params) {
      return await UserApi.refreshToken(params);
    },
    setColorsToStatistics(user) {
      if (user.genre_statistics) {
        let total = 0;
        const colors = ["#2EFFED", "#FF5757", "#9F08A3", "#6C69FF"];
        for (let i = 0; i < user.genre_statistics.length; i++) {
          total += user.genre_statistics[i].percentage;
          if (
            user.genre_statistics[i].genre ||
            (user.genre_statistics[i].name &&
              user.genre_statistics[i].name !== "otros")
          ) {
            user.genre_statistics[i].color = colors[i];
          } else {
            user.genre_statistics[i].color = colors[3];
          }
        }
        if (total === 0) {
          for (let i = 0; i < user.genre_statistics.length; i++) {
            user.genre_statistics[i].percentage = 25;
          }
        }
      }
    },
    async getMyUser() {
      this.userDetail = await UserApi.getMyUser();
      this.setColorsToStatistics(this.userDetail);
    },
    showMaintenancePage() {
      this.maintenancePage = true;
    },
    async init() {
      try {
        this.initialConfig = await UserApi.getInitial();
        if (this.initialConfig.apple_pay !== undefined) {
          this.started = true;
        } else {
          this.serverError = true;
        }
      } catch (e) {
        this.serverError = true;
      }
    },
    async auth(payload) {
      this.userTokens = await UserApi.auth(payload);
    },
    async sendBuyer(payload) {
      this.buyer = await UserApi.sendBuyer(payload);
    },
    async validatePromoter() {
      await UserApi.validatePromoter();
    },
    async updateUser(params) {
      this.userDetail = {
        ...this.userDetail,
        ...(await UserApi.updateUser(params))
      };
    },
    async updateAccount(params) {
      await UserApi.updateAccount(params);
      // TODO: revisar que pasa si hay error (Envia un 403 con un detail con el error)
    },
    async updatePrivacy(params) {
      let profileStatus;
      if (params.profile_status === "0") profileStatus = "public";
      else if (params.profile_status === "1") profileStatus = "only_friends";

      this.userDetail.privacy_configuration ||= {};
      this.userDetail.privacy_configuration.profile_status = profileStatus;
      await UserApi.updatePrivacy(params);
    },
    async updateImageUser(params) {
      this.userDetail.image_url = (await UserApi.updateImageUser(params)).image;
    },
    async followGenre(payload) {
      await UserApi.followGenre(payload);
      this.userDetail.genres = payload.genres.map(id => {
        return { id: id };
      });
    },
    async getFriends(params) {
      this.list = await UserApi.getUsers(params);
    },
    async getSearchUsers(params) {
      this.listUsers = await UserApi.getUsers(params);
    },
    async getChatMembers({ chats, page_size = undefined, page = undefined }) {
      this.list.chatMembers = await UserApi.getChatMembers({
        chats,
        page_size,
        page
      });
    },
    async getUser(slug) {
      this.user = await UserApi.getUser(slug);
      this.setColorsToStatistics(this.user);
    },
    async getUsers(param) {
      this.users = await UserApi.getUsers(param);
    },
    async getUserStatus() {
      this.userStatus = await UserApi.getUserStatus();
    },
    async getMyTickets(params) {
      let tickets = await UserApi.getMyTickets(params);
      if (params?.all) {
        this.myTickets = [];
        tickets.forEach(data => {
          if (data.event_info.fan_to_fan_active) {
            for (const ticket of data.tickets) {
              if (ticket.fan_to_fan_status !== -1) {
                this.myTickets.push({
                  eventId: data.event_info.id,
                  id: ticket.id,
                  thumbnails: data.event_info.thumbnails,
                  title: data.event_info.title,
                  permalink: data.event_info.permalink,
                  slug: data.event_info.slug,
                  ticket_type: ticket.title,
                  fan_to_fan_active: data.event_info.fan_to_fan_active,
                  fan_to_fan_status: ticket.fan_to_fan_status,
                  venue: data.event_info.venue,
                  start_date: data.event_info.start_date
                });
              }
            }
          }
        });
      } else {
        this.myTickets = tickets;
      }
    },
    async getTicketsWeswap(groupByEvent, params = {}) {
      const defaultPerPage = 12;
      const today = new Date();
      const paramsObj = {
        page: params.page || 1,
        page_size: params.page_size || defaultPerPage,
        count: true,
        from_date: params.from_date || today.toISOString().split("T")[0],
        ordering: params.ordering || "start_date"
      };
      let weswapAvailable = await UserApi.getTicketsWeswap(paramsObj);
      if (paramsObj.page === 1) {
        this.weswapEvents.available_weswap = [];
      }
      let eventsAdd = [];
      weswapAvailable.available_weswap.forEach(data => {
        if (
          !eventsAdd.includes(data.event.id) &&
          new Date(data.event.start_date) > new Date()
        ) {
          let eventTitle = "";
          if (groupByEvent) {
            eventsAdd.push(data.event.id);
            eventTitle = data.event.title;
          } else {
            eventTitle =
              data.event.title +
              " <small> (" +
              data.ticket_transfer_tier.title +
              ")</small>";
          }
          this.weswapEvents.available_weswap.push({
            id: data.ticket_transfer_tier.id,
            eventId: data.event.id,
            slug: data.event.slug,
            title: eventTitle,
            venue: data.event.venue,
            price: data.ticket_transfer_tier.price,
            currency: data.ticket_transfer_tier.currency,
            enabled: true,
            start_date: data.event.start_date,
            thumbnails: data.event.thumbnails,
            ticket_types_count: 1
          });
        }
      });
      this.weswapEvents.count = weswapAvailable.count;
      this.weswapEvents.next_page = weswapAvailable.next_page;
      this.weswapEvents.page_size = params.page_size || defaultPerPage;
    },
    async getEventInvoices(parmas) {
      this.invoices = (await UserApi.getEventInvoices(parmas)).purchases;
    },
    async getEventPurchasesCoverage(parmas) {
      this.purchasesCoverage = (
        await UserApi.getEventPurchasesCoverage(parmas)
      ).purchases;
    },
    async getPerfectCompanion() {
      this.perfectCompanion =
        (await UserApi.getPerfectCompanion())?.perfect_companion[0] || [];
    },
    async promoterValidation() {
      await apiService.post(`/promoter-registration/`);
    },
    setGenres(genresToSet) {
      this.genres = genresToSet;
      return this.genres;
    },
    // fin de lo Borrable
    async getArtistFollowers(params) {
      this.artistFollowers = this.userDetail?.id
        ? (await UserApi.getUsers(params)).users
        : [];
    },
    async getEventFollowers(params) {
      this.eventFollowers = this.userDetail?.id
        ? (await UserApi.getUsers(params)).users
        : [];
    },
    async getVenueFollowers(params) {
      this.venueFollowers = this.userDetail?.id
        ? (await UserApi.getUsers(params)).users
        : [];
    },
    async getCampaignFollowers(params) {
      this.campaignFollowers = this.userDetail?.id
        ? (await UserApi.getUsers(params)).users
        : [];
    },
    async getUsersLikedMoment(params) {
      this.usersLikedMoment = (await UserApi.getUsers(params)).users;
    },
    // TODO: borrar metodos
    async companyFollowers(payload) {
      await apiService
        .get(`/users/`, {
          params: payload
        })
        .then(res => {
          this.companyFollowers = res.data;
        })
        .catch(res => {
          if (typeof window !== "undefined") {
            if (typeof CustomEvent === "function") {
              var event = new CustomEvent("showError", {
                detail: {
                  textI18N: "error.title",
                  error: res
                }
              });
              window.dispatchEvent(event);
            }
          }
        });
    },
    async increaseCountFollowers() {
      this.artistFollowers.count++;
    },
    async decreaseCountFollowers() {
      this.artistFollowers.count--;
    },
    async setFriendshipStatus(params) {
      await UserApi.setFriendshipStatus(params);
    },
    async deleteFriendship(params) {
      await UserApi.deleteFriendship(params);
    },
    async closeSession() {
      await UserApi.closeSession();
    },
    cardsClean() {
      this.cards = [];
    },
    async changeUserLanguage(payload) {
      await apiService
        .patch(`/users/${payload.user}/`, {
          language: payload.language
        })
        .then(res => {
          this.detail.language = payload.language;
        })
        .catch(res => {
          if (typeof window !== "undefined") {
            if (typeof CustomEvent === "function") {
              var event = new CustomEvent("showError", {
                detail: {
                  textI18N: "error.title",
                  error: res
                }
              });
              window.dispatchEvent(event);
            }
          }
        });
    },
    setLocation(data) {
      this.location.latitude = data.latitude;
      this.location.longitude = data.longitude;
    },
    // Fin del Borrar

    // especial cals
    async forgetPassword(payload) {
      await UserApi.forgetPassword(payload);
    },
    async changePassword(payload) {
      await UserApi.changePassword(payload);
    },
    async deleteLocation(locationId) {
      this.userDetail.locations = this.userDetail.locations.filter(
        ({ id }) => id !== locationId
      );
      await UserApi.deleteLocation(locationId);
    },
    async addLocation(location) {
      this.userDetail.locations.push(location);
      const userLocation = (await UserApi.addLocation(location)).data;
    },
    async validateTokenPassword(payload) {
      await UserApi.validateTokenPassword(payload);
    },
    async validateTokenUnsubscribe(payload) {
      await UserApi.validateTokenUnsubscribe(payload);
    },
    async unsubscribeEmails(payload) {
      await UserApi.unsubscribeEmails(payload);
    },
    async confirmEmail(payload) {
      return await UserApi.confirmEmail(payload);
    },
    async deleteAccount() {
      await UserApi.deleteAccount();
    }
  }
});
