import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import App from "./App.vue";
import router from "./router/routes";
import VueRouter from "vue-router";
import * as Vue from "vue";
import * as Sentry from "@sentry/vue";
import axios from "axios";
import VueAxios from "vue-axios";
// import store from "./store";
import "./assets/main.scss";
// import "./assets/main-wegow.scss";
// import "./assets/variables.scss";
import { createI18n } from "vue-i18n";
import { api } from "@/plugins/api";
// import { BrowserTracing } from "@sentry/tracing";
import VueGtag from "vue-gtag";
import "@/assets/tailwind.css";

const es = require("./locales/es.json");
const en = require("./locales/en.json");
const de = require("./locales/de.json");
const it = require("./locales/it.json");
const pt = require("./locales/pt.json");
const fr = require("./locales/fr.json");
const numberFormats = {
  es: {
    currency: {
      style: "currency",
      currency: "EUR"
    }
  },
  en: {
    currency: {
      style: "currency",
      currency: "USD"
    }
  },
  de: {
    currency: {
      style: "currency",
      currency: "EUR"
    }
  },
  it: {
    currency: {
      style: "currency",
      currency: "EUR"
    }
  },
  fr: {
    currency: {
      style: "currency",
      currency: "EUR"
    }
  },
  pt: {
    currency: {
      style: "currency",
      currency: "EUR"
    }
  }
};

const i18n = createI18n({
  legacy: false,
  warnHtmlInMessage: "off",
  locale: "es",
  fallbackLocale: "es",
  silentTranslationWarn: true,
  numberFormats,
  messages: {
    es,
    en,
    de,
    it,
    pt,
    fr
  }
});
const app = Vue.createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

pinia.use(api);
app.config.debug = true;
app.config.devtools = true;

app
  .use(i18n)
  .use(VueAxios, axios)
  .provide("axios", app.config.globalProperties.axios)
  .use(router)
  .use(VueRouter)
  .use(pinia)
  .use(VueGtag, {
    config: {
      id: "G-V5SVM4LZ1D",
      anonymizeIp: true,
      ecommerce: {
        enabled: true
      }
    },
    enabled: process.env.VUE_APP_NUXT_ENV === "production"
    // property: "UA-260030999-1",
    // // property: "G-V5SVM4LZ1D",
    // appName: "Wegow 3 - GA4",
    // // pageTrackerScreenviewEnabled: true,
    // // enabled: true,
    // consfig: {
    //   anonymize_ip: true
    // }
  });

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.VUE_APP_NUXT_ENV,
  ations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    "localhost",
    /^http:\/\/(www\.)?wegowlocal\.com/,
    /^https:\/\/(www\.)?(beta)?wegow\.com/
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  beforeSend(event, _hint) {
    try {
      let externalTagName;
      const ignorableFiles = ["smartclip", "stickyscroller"];
      const isExternalException = event.exception.values.some(error =>
        error.stacktrace.frames.some(frame =>
          ignorableFiles.some(toIgnore => {
            externalTagName = toIgnore;
            return frame.filename.includes(toIgnore);
          })
        )
      );

      if (isExternalException) {
        event.tags = event.tags || {};
        event.tags.external = externalTagName;
      }
    } catch {}
    return event;
  }
});

app.mount("#app");
